<template>
  <el-drawer
    :size="size"
    :visible.sync="_visible"
    :with-header="false"
    :modal="false"
    :destroy-on-close="true"
    :z-index="999"
  >
    <i id="close-btn" class="el-icon-close" @click="_visible = false"></i>
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "DrawerComponent",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Number],
      default: "50%",
    },
  },
  data() {
    return {};
  },
  computed: {
    _visible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit("update:visible", value);
      },
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-drawer {
  overflow: visible !important;
}

#close-btn {
  padding: 15px 8px;
  background-color: #304056;
  color: #ffffff;
  position: absolute;
  top: 30vh;
  transform: translateX(-100%);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  &:hover {
    cursor: pointer;
  }
}

.title {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #304056;
  background: white;
  border-bottom: 3px solid #F3F4F6;
  position: absolute;
  z-index: 999;
}

.content {
  margin-top: 50px;
  padding: 5px 15px 50px 15px;
}
</style>
