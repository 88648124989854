<template>
  <div class="root">
    <el-pagination
      background
      :current-page="_pagination.page"
      :page-size="_pagination.perPage"
      :page-sizes="[5, 10, 15, 20, 50]"
      :total="_pagination.total"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    pagination: {
      type: Object,
      default: function () {
        return {
          page: 1, // 初始页码
          perPage: 15, // 默认每页展示条数
          total: 0, // 数据总条数
        };
      },
    },
    getTableDataFunc: {
      type: Function,
      default: function () {},
    },
  },
  computed: {
    _pagination: {
      get() {
        return this.pagination;
      },
      set(val) {
        this.$emit("update:pagination", val);
      },
    },
  },
  methods: {
    handleSizeChange(size) {
      this._pagination.perPage = size;
      this.getTableDataFunc();
    },
    handlePageChange(page) {
      this._pagination.page = page;
      this.getTableDataFunc();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>>
